import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import { Button, Footer, Input, SelectWithLabel, Notification, Steps, FacebookPixels, SEO } from 'components'
import lockIcon from 'assets/lock.svg'
import logoImage from 'assets/fmfLogoBlack.png'

import { actionCreators, selectors } from 'state/ShippingPage/reducer'
import { selectCartId } from 'state/HomePage/reducer'
import { actionCreators as loginActions, selectors as loginSelectors } from 'state/LoginPage/reducer'
import * as actions from 'state/ShippingPage/actions'
import * as styles from './ShippingPage.module.scss'

const ShippingPage = props => {
  const { loginState, shippingState, setPageAfterLogin } = props
  // history.listen(listener => {
  //   if (listener.pathname.match(/(product)|(shipping)|(billing)|(upsell)|(sticker)|(upgrade)/)) {
  //     history.goForward()
  //   }
  // })

  const { shared, landing } = useSiteMetadata()

  const countries = landing.COUNTRIES

  const countriesRegions = {}

  const countriesOptions = Object.values(countries).map(country => {
    countriesRegions[country.id] = country.regions.map(region => ({
      value: region.id,
      label: region.name,
      autocomplete: region.iso2
    }))

    return {
      value: country.id,
      label: country.name,
      autocomplete: country.name
    }
  })

  const [isShowErrors, setIsShowErrors] = useState(false)
  const [notification, showNotification] = useState()

  const [selectedCountryOption, setSelectedCountryOption] = useState(countriesOptions[0])
  const [regionsOptions, setRegionOptions] = useState(countriesRegions[countriesOptions[0].value])

  useEffect(() => {
    trackingGA()
  }, [])

  useEffect(() => {
    setPageAfterLogin(props.location.pathname)
  }, [props.location.pathname, setPageAfterLogin])

  useEffect(() => {
    // showNotification("Product added to cart");
    if (loginState.email !== '') {
      props.setFirstName(loginState.firstName)
      props.setLastName(loginState.lastName)
      props.setZipCode(loginState.zipCode)
      props.setAddress(loginState.address)
      props.setAddress2(loginState.address2)
      props.setCity(loginState.city)
      props.setEmail(loginState.email)
      props.setPhone(loginState.phone)

      const region = countriesRegions[selectedCountryOption.value].find(
        region => region.value === loginState.state || region.autocomplete === loginState.state || region.label === loginState.state
      )
      if (region) {
        props.setState(region)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState])

  useEffect(() => {
    showNotification(shippingState.UIState.error)
  }, [shippingState.UIState.error])

  const countryChangedHandler = selectedCountryOption => {
    setRegionOptions(countriesRegions[selectedCountryOption.value])
    setSelectedCountryOption(selectedCountryOption)
    props.setCountry(selectedCountryOption)
    props.setState('')
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (shippingState.isValid) {
      const { payload: isInfoAdded } = await props.addShippingInfoToCart()
      if (isInfoAdded) {
        navigate('/billing')
        return true
      }
    }
  }

  return (
    <div className={styles.shippingPage}>
      {/* <PreventDirectLink cartId={cartId} /> */}
      <SEO pathname='/shipping' />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='InitiateCheckout' />
      <img className={styles.logo} src={logoImage} alt='fmf' />
      <Steps step={2} name='Choose' />
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          {['ANONYMOUS'].includes(loginState.membershipInfo.status) && (
            <div className={styles.titleOffer}>
              <p className={styles.offerText}>Up to $30 off to members </p>
              <span className={styles.offerLink} onClick={() => navigate('/login')}>
                Login
              </span>
            </div>
          )}
          <p className={styles.titleDescription1}>Tell us where to send your shirt.</p>
        </div>

        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.formItemRow}>
            <div className={styles.rowHalfWidth}>
              <Input
                placeholder='First Name'
                label='First Name'
                onChange={props.setFirstName}
                isValid={shippingState.validation.firstName}
                isShowErrors={isShowErrors}
                errorMessage='Please input your First Name'
                defaultValue={shippingState.firstName}
                name='first-name'
                autoComplete='name first-name'
                required
              />
            </div>
            <div className={styles.rowHalfWidth}>
              <Input
                placeholder='Last Name'
                label='Last Name'
                onChange={props.setLastName}
                isValid={shippingState.validation.lastName}
                isShowErrors={isShowErrors}
                errorMessage='Please input your Last Name'
                defaultValue={shippingState.lastName}
                name='last-name'
                autoComplete='name last-name'
                required
              />
            </div>
          </div>
          <div className={styles.formItemRow}>
            <div className={styles.rowFullWidth}>
              <Input
                placeholder='Address 1'
                label='Address'
                onChange={props.setAddress}
                isValid={shippingState.validation.address}
                isShowErrors={isShowErrors}
                errorMessage='Please input your Address'
                defaultValue={shippingState.address}
                name='street-address'
                autoComplete='shipping street-address'
                required
              />
            </div>
            </div>
          <div className={styles.formItemRow}>
            <div className={styles.rowFullWidth}>
              <Input
                placeholder='Address 2'
                label='Address 2'
                onChange={props.setAddress2}
                isShowErrors={false}
                errorMessage='Please input your Address 2'
                defaultValue={shippingState.address2}
              />
            </div>
          </div>

          <div className={styles.formItemRow}>
            <div className={styles.rowHalfWidth}>
              <Input
                placeholder='City'
                label='City'
                onChange={props.setCity}
                isValid={shippingState.validation.city}
                isShowErrors={isShowErrors}
                errorMessage='Please input your City'
                defaultValue={shippingState.city}
                name='city'
                autoComplete='shipping address-level2'
                required
              />
            </div>
            <div className={styles.rowHalfWidth}>
              <Input
                placeholder='Zip Code'
                label='Zip Code'
                onChange={props.setZipCode}
                isValid={shippingState.validation.zipCode.isValid}
                isShowErrors={isShowErrors}
                errorMessage={shippingState.validation.zipCode.error}
                defaultValue={shippingState.zipCode}
                name='postal-code'
                autoComplete='shipping postal-code'
                required
              />
            </div>
          </div>

          <div className={styles.formItemRow}>
            <div className={styles.rowHalfWidth}>
              <SelectWithLabel
                placeholder='Select State'
                label='State'
                onSelect={props.setState}
                isValid={shippingState.validation.state}
                isShowErrors={isShowErrors}
                errorMessage='Please select your State'
                options={regionsOptions}
                value={shippingState.stateOption}
                inputProps={{
                  name: 'state',
                  autoComplete: 'shipping address-level1'
                }}
                required
              />
            </div>
            <div className={styles.rowHalfWidth}>
              <SelectWithLabel
                placeholder='United States of America'
                label='Country'
                alreadySelected
                onSelect={countryChangedHandler}
                isValid={shippingState.validation.country}
                isShowErrors={isShowErrors}
                errorMessage='Please select your Country'
                value={selectedCountryOption}
                options={countriesOptions}
                required
              />
            </div>
          </div>
          {loginState.email === '' && (
            <div className={styles.formItemRow}>
              <div className={styles.rowFullWidth}>
                <Input
                  placeholder='Email Address'
                  label='Email Address'
                  onChange={props.setEmail}
                  isValid={shippingState.validation.email.isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={shippingState.validation.email.error}
                  defaultValue={shippingState.email}
                  name='email'
                  autoComplete='home email'
                  required
                />
              </div>
            </div>
          )}

          {loginState.phone === '' && (
            <div className={styles.formItemRow}>
              <div className={styles.rowFullWidth}>
                <Input
                  placeholder='Phone Number'
                  label='Phone Number'
                  onChange={props.setPhone}
                  isValid={shippingState.validation.phone.isValid}
                  isShowErrors={isShowErrors}
                  errorMessage={shippingState.validation.phone.error}
                  defaultValue={shippingState.phone}
                  name='phone'
                  autoComplete='phone number'
                  required
                />
              </div>
            </div>
          )}

          <div className={styles.button}>
            <Button value='Continue' loading={shippingState.UIState.isLoading} />
          </div>
        </form>
        <div className={styles.secureInfo}>
          <img className={styles.secureIcon} src={lockIcon} alt='' />
          <p className={styles.secureInfoText}>Secure Checkout</p>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  cartId: selectCartId(state),
  loginState: loginSelectors.selectLoginState(state),
  shippingState: selectors.selectShippingState(state)
})

const mapDispatchToProps = {
  setPageAfterLogin: loginActions.setPageAfterLogin,

  setFirstName: actionCreators.setFirstName,
  setLastName: actionCreators.setLastName,
  setAddress: actionCreators.setAddress,
  setAddress2: actionCreators.setAddress2,
  setCity: actionCreators.setCity,
  setCountry: actionCreators.setCountry,
  setZipCode: actionCreators.setZipCode,
  setState: actionCreators.setState,
  setEmail: actionCreators.setEmail,
  setEmailConfirm: actionCreators.setEmailConfirm,
  setPhone: actionCreators.setPhone,
  addShippingInfoToCart: actions.addShippingInfoToCart
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPage)

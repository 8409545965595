// extracted by mini-css-extract-plugin
export var shippingPage = "ShippingPage-module--shippingPage--2Z43Y";
export var logo = "ShippingPage-module--logo--2eV3J";
export var content = "ShippingPage-module--content--34pq7";
export var titleContainer = "ShippingPage-module--titleContainer--J-qs3";
export var titleOffer = "ShippingPage-module--titleOffer--BdUro";
export var offerText = "ShippingPage-module--offerText--3hraF";
export var offerLink = "ShippingPage-module--offerLink--3HKj8";
export var titleDescription = "ShippingPage-module--titleDescription--1nv96";
export var titleDescription1 = "ShippingPage-module--titleDescription1--1sbNA";
export var formContainer = "ShippingPage-module--formContainer--1ddws";
export var formItemRow = "ShippingPage-module--formItemRow--1jSud";
export var formItemRowEmail = "ShippingPage-module--formItemRowEmail--3VwVj";
export var rowFullWidth = "ShippingPage-module--rowFullWidth--1NXE4";
export var rowHalfWidth = "ShippingPage-module--rowHalfWidth--3mxse";
export var formItemRowAddress1 = "ShippingPage-module--formItemRowAddress1--1fHYa";
export var formItemRowAddress2 = "ShippingPage-module--formItemRowAddress2--2WyJo";
export var label = "ShippingPage-module--label--3jMtW";
export var button = "ShippingPage-module--button--18Scq";
export var secureInfo = "ShippingPage-module--secureInfo--_nLvo";
export var secureIcon = "ShippingPage-module--secureIcon--s5uWQ";
export var secureInfoText = "ShippingPage-module--secureInfoText--8Pb1l";
export var footerContainer = "ShippingPage-module--footerContainer--1O5rk";